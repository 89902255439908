// //////////////////////////////////////////////////////////////////////
// This file is generated in package.json under scripts/prebuild:config
// To change values here, don't edit this file. Edit /app.config.js
// and rerun 'npm run prebuild:config'
// //////////////////////////////////////////////////////////////////////
// Setup the env to match what we built with
if (globalThis.process !== undefined) {
	Object.assign(process.env, {
		NODE_ENV: 'production',
		RUBBER_BUILD_ENV: 'prod',
	});
}

// Import THIS in the front end instead of AppConfig
// so nothing private is exposed.
const PublicAppConfig = {
	...({
	"fbAppId": "1437687873686616",
	"fbBizLoginConfigId": "1189003865100280",
	"fbApiVersion": "v18.0",
	"langfuse": {},
	"honeycomb": {},
	"languages": {
		"en": {
			"nativeName": "English",
			"longCode": "en-US",
			"simpleCode": "en"
		},
		"es": {
			"nativeName": "Spanish",
			"longCode": "es-MX",
			"simpleCode": "es"
		},
		"zh": {
			"nativeName": "Chinese",
			"longCode": "zh",
			"simpleCode": "zh"
		},
		"ar": {
			"nativeName": "Arabic",
			"longCode": "ar",
			"simpleCode": "ar"
		}
	},
	"vehicleTiers": {
		"sedan": {
			"title": "Luxury Sedan",
			"exampleText": "Mercedes-Benz E-Class, BMW 5 Series, Cadillac XTS or similar",
			"tierIndex": 1,
			"maxPassenger": 3
		},
		"suv": {
			"title": "Luxury SUV",
			"exampleText": "Mercedes-Benz V-Class, Chevrolet Suburban, Cadillac Escalade, Toyota Alphard or similar",
			"tierIndex": 2,
			"maxPassenger": 5
		},
		"limo": {
			"title": "Limo",
			"tierIndex": 3,
			"exampleText": "Cadillac Escalade ESV Stretch Limo or similar",
			"maxPassenger": 14
		},
		"exotic": {
			"title": "Exotic",
			"description": "Ferrari F8, Lamborghini Huracán, McLaren 720S, or similar",
			"tierIndex": 4,
			"maxPassenger": 2
		}
	},
	"newYorkCompliance": {
		"tlcLicensedForHireVehicleBases": "\n\t\t\t\t\t\tB03338 A & T NYC LIMO LLC\n\t\t\t\t\t\tB03340 CEO CHAUFFEURED SERVICES CORP\n\t\t\t\t\t\tB03321 CRESCENT LUXURY CLS, INC.\n\t\t\t\t\t\tB02253 CRESTWOOD CAR & LIMO SVC,LTD\n\t\t\t\t\t\tB02424 FRIENDLY RIDE INC\n\t\t\t\t\t\tB03197 LIMO TRIBE INC\n\t\t\t\t\t\tB03320 LIMOLINK INCORPORATED\n\t\t\t\t\t\tB02655 LONG ISLAND CITY CAR AND LIMO INC.\n\t\t\t\t\t\tB02949 MEEM SERVICES INC\n\t\t\t\t\t\tB02298 NYG LIMOUSINE LLC\n\t\t\t\t\t\tB02727 OSCAR LIMOUSINE INC\n\t\t\t\t\t\tB02027 PARIS LIMOUSINE SERVICE, CORP\n\t\t\t\t\t\tB02611 SS LIMOUSINE SERVICE INC\n\t\t\t\t\t\tB03334 WTM 365 INC.\n\t\t\t\t\t\tB03317 V & C, INC\n\t\t\t\t\t"
	},
	"tcpPort": 8095,
	"apiHost": "api.vayadriving.com",
	"gpsWorkerPublic": {
		"devWorkerRoot": "https://vaya-dev-gps.ngrok.io",
		"prodWorkerRoot": "https://gps-worker.vaya.workers.dev"
	},
	"valetTicketListWorkerPublic": {
		"devWorkerRoot": "https://vaya-dev-valet-ticket-list.ngrok.io",
		"prodWorkerRoot": "https://valet-ticket-list.vaya.workers.dev",
		"defaultEnabled": {
			"dev": false,
			"prod": false
		}
	},
	"valetTicketEditWorkerPublic": {
		"devWorkerRoot": "https://vaya-dev-valet-ticket-edits.ngrok.io",
		"prodWorkerRoot": "https://valet-ticket-edits.vaya.workers.dev"
	},
	"envRootUrls": {
		"dev": {
			"frontend": "http://localhost:3000",
			"api": "localhost:8095",
			"partners": "http://localhost:3001",
			"marketing": "http://localhost:8000"
		},
		"staging": {
			"frontend": "https://staging.vayadriving.com",
			"api": "api-staging.vayadriving.com",
			"partners": "https://partner.vayadriving.com",
			"marketing": "http://localhost:8000"
		},
		"prod": {
			"frontend": "https://app.vayavalet.io",
			"api": "api.vayadriving.com",
			"partners": "https://partner.vayadriving.com",
			"marketing": "https://vayadriving.com"
		}
	},
	"gigWageClientRoot": "https://gigwage.com",
	"gaId": "UA-243284-47",
	"datadogClientToken": "pub26d2712cf99dbf5c547a771b587ac435",
	"datadogCFWorkersClientToken": "pub616351f86683bfbde777edf2081a0b00",
	"stripePubKey": "pk_live_51JW34iKJ8wMu6m4anGsfU7QZMvhIoAZSA2NnOjwNsGxZGOMIPGzMJn0toX0ECSYcSrIWhBmFzEr8HPgSG3SGwM0F00Ith8JJfx",
	"sentryDsn": "https://b44afb4cb55443ae89d6c520c2c921eb@o1050529.ingest.sentry.io/6031794",
	"googleMapsApiKey": "AIzaSyD3bPj5A2KwUi5_yw9CuFBvZcIhQi8wXbs",
	"geolocationDbKey": "fb363670-e22a-11eb-a464-59f706281067",
	"frontendRoot": "https://app.vayavalet.io",
	"frontendV2Root": "https://admin.vayadriving.com",
	"frontendTunnelRoot": "https://vaya-dev-client.ngrok.io",
	"frontendValetApp": "https://app.vayavalet.io",
	"partnerRoot": "https://partner.vayadriving.com",
	"partnerTunnelRoot": "https://vaya-dev-partners.ngrok.io",
	"marketingRoot": "https://vayadriving.com",
	"marketingTunnelRoot": "https://vaya-dev-marketing.ngrok.io",
	"betaAppProdRoot": "https://admin.vayadriving.com",
	"tipAppRoot": "https://tip.vayavalet.io",
	"tipAppDevTunnelRoot": "https://vaya-dev-tip.ngrok.io",
	"apiVayaValetIo": "api.vayavalet.io",
	"backendTunnelHost": "vaya-dev-api.ngrok.io",
	"pinExpiration": 3600000,
	"defaultStripeProductId": "prod_KkRtdu8whs2YeG",
	"stripePackages": {
		"prod_KkRxJlhMOgMnv1": {
			"name": "Introductory Membership",
			"priceId": "price_1K4x3oKJ8wMu6m4aZV67tPbP",
			"price": 0,
			"hours": 0,
			"markup": 1.33,
			"customDescription": "Experience all the benefits of a private luxury driver in our fleet of luxury sedans, suburbans, and limos with no monthly subscription for 60 days",
			"referralCodeId": "Tier-Intro"
		},
		"prod_Lg4V8UlnGi0gZK": {
			"name": "Basic Package",
			"priceId": "price_1KyiM6KJ8wMu6m4aUqV52dJf",
			"price": 19,
			"hours": 0.25,
			"markup": 1.25,
			"customDescription": "Get a complimentary ride each month and select dedicated private drivers your own dedicated concierge staff",
			"referralCodeId": "Tier-Basic"
		},
		"prod_Lg4XIpclXrFV9x": {
			"name": "Advanced Package",
			"priceId": "price_1KyiOYKJ8wMu6m4aNpkhFVtX",
			"price": 99,
			"hours": 1,
			"markup": 1.2,
			"customDescription": "Priority access to exclusive luxury transportation and lifestyle Concierge services with personal vehicle driving and access to limousine fleets",
			"referralCodeId": "Tier-Elite"
		},
		"prod_KkRtdu8whs2YeG": {
			"name": "Introductory Membership",
			"priceId": "price_1K6kyoKJ8wMu6m4aguRcbQHF",
			"price": 0,
			"hours": 0,
			"markup": 1.33,
			"customDescription": "Experience all the benefits of a private luxury driver in our fleet of luxury sedans, suburbans, and limos with no monthly subscription for 60 days",
			"referralCodeId": "Tier-Intro"
		},
		"prod_Lg6LhWkkPf6QLo": {
			"name": "Basic Package",
			"priceId": "price_1Kyk95KJ8wMu6m4a7Z8wdf88",
			"price": 19,
			"hours": 0.25,
			"markup": 1.25,
			"customDescription": "Get a complimentary ride each month and select dedicated private drivers your own dedicated concierge staff",
			"referralCodeId": "Tier-Basic"
		},
		"prod_Lg6L8KneyZHmpr": {
			"name": "Advanced Package",
			"priceId": "price_1Kyk9DKJ8wMu6m4aS59g4iWy",
			"price": 99,
			"hours": 1,
			"markup": 1.2,
			"customDescription": "Priority access to exclusive luxury transportation and lifestyle Concierge services with personal vehicle driving and access to limousine fleets",
			"referralCodeId": "Tier-Elite"
		}
	},
	"phoneNumbers": {
		"main": "+15127227795",
		"notifications": "+15127227796",
		"driverMember": "+15127227797",
		"otp": "+17372379502"
	},
	"firebase": {
		"apiKey": "AIzaSyCXqd6yRHsk8QjoUv0rsCuuRPKp3tpgvtY",
		"authDomain": "vaya-driving-app.firebaseapp.com",
		"projectId": "vaya-driving-app",
		"storageBucket": "vaya-driving-app.appspot.com",
		"messagingSenderId": "747446149493",
		"appId": "1:747446149493:web:ad86b9458929bdbf97333c",
		"measurementId": "G-5S2VE51YW9"
	},
	"firebaseVapidMessagingKey": "BIi6SEv4QrUl35sfg1PZe0TuFEgsD5RTjR-mMvN9PRO7G3UT3BDvZ5NuIpAGq5jYsnCh7uwETngvOV68cKGaHXo",
	"intercomAppId": "sxietg3v",
	"driverMaxPickupWaitMinutes": 60,
	"serviceAreas": [
		{
			"name": "Austin, TX",
			"timezone": "America/Chicago",
			"location": {
				"lat": 30.268064208179236,
				"lng": -97.74281181323757,
				"radius": 48280,
				"dispatchRadiusMeters": 48280.3
			},
			"eventPricingRules": [
				{
					"id": "late-night",
					"name": "Late Night",
					"description": "Late night trips on high-demand days",
					"specialPricingModifier": 2.5,
					"packageModifiers": {
						"silver": 1.6,
						"gold": 1.4,
						"platinum": 1.3,
						"diamond": 1.2
					},
					"times": [
						{
							"dayOfWeek": -1,
							"start": "19:45:00",
							"hours": 5
						},
						{
							"dayOfWeek": 5,
							"start": "20:00:00",
							"hours": 7
						},
						{
							"dayOfWeek": 6,
							"start": "20:00:00",
							"hours": 7
						},
						{
							"dayOfWeek": 0,
							"start": "20:00:00",
							"hours": 7
						}
					]
				}
			],
			"flatRateGeoRules": [
				{
					"id": "ride-25-downtown",
					"name": "$25 Rides",
					"description": "$25-One-Way Rides anywhere within the downtown radius",
					"flatRateCost": 20,
					"flatRatePrice": 25,
					"geofences": [
						{
							"lat": 30.263382454053133,
							"lng": -97.74454989455865,
							"meters": 2125
						},
						{
							"lat": 30.26326313645714,
							"lng": -97.72417986827942,
							"meters": 1820
						},
						{
							"lat": 30.268864673875296,
							"lng": -97.7243221368536,
							"meters": 1820
						}
					]
				}
			]
		}
	],
	"appStoreLinks": {
		"android": "https://play.google.com/store/apps/details?id=com.vayadriving",
		"ios": "https://apps.apple.com/us/app/vaya-concierge-driving/id1589326725"
	},
	"posthogCloudApiKey": "phc_7SelwAJSXVDNNSFp4kYlbiEe4bhkNL29PIicLTaurBj"
}),
	
	version: '2.3.1222-0a53b64-prod',

	gitVersionLong: '0a53b64a8d0a977d5b033c575d5c085ac9c0e5eb',
	gitVersionShort: '0a53b64a8',

	buildEnv: 'prod',

	buildTime: '2024-11-20T18:56:05.404Z',

	buildArch: 'armv7l',

	buildPlatform: 'linux',

	// Generated as an MD5 of package-lock.json so we can tell if hotfix will work or not
	depHash: '4905a7a36ec7385e14b7ccf973d20124',

	// Set in Jenkins via the BUILD_NUMBER env var
	buildNumber: 'undefined',

};

export default PublicAppConfig;
